<template>
  <div>
    <p v-if="preview && visibleStatuses?.length || !preview" class="text-xs mb-2 font-semibold">{{ statusType.text }}</p>
    <div class="flex gap-2 flex-col">
      <template v-if="preview">
        <StatusSettingsItem
          v-for="status in visibleStatuses"
          :key="status.id"
          :parent-id="parentId"
          :source="source"
          :status="status"
          :preview="true"
        />
      </template>
      <template v-else>
        <StatusSettingsItem
          v-for="status in visibleStatuses"
          :key="status.id"
          :parent-id="parentId"
          :source="source"
          :status="status"
          :can-mutate="canMutate"
          @delete="onConfirmDeleteStatus(status)"
          @save="(formState, form) => onUpdateStatus(formState, form, status)"
        />
        <template v-if="addInputVisible">
          <StatusSettingsItem
            :parent-id="parentId"
            :source="source"
            :status="{
              name: '',
              type: statusType.value,
            }"
            @delete="addInputVisible = false"
            @save="onCreateStatus"
          />
        </template>
        <div
          v-else
          class="flex text-sm items-center border-dashed border border-gray-300 gap-2 p-2 rounded-md cursor-pointer"
          @click="addInputVisible = true"
        >
          <UIcon name="i-heroicons-plus" />
          Add status
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { type STATUS_TYPE } from '#status/constant'
import type { Status, DeleteStatusParams } from '#status/types'
import { LazyStatusSettingsDeleteModal, LazyDeleteModal } from '#components'

const props = defineProps<{
  statusType: {
    value: STATUS_TYPE
    text: string
  }
  statuses?: Status[]
  parentId: string
  source: MorphSource
  preview?: boolean
}>()

const emit = defineEmits(['created'])

const toast = useToast()
const modal = useModal()
const { preventClose } = useBoardSettingsNavigator()
const { createStatus } = useCreateStatus()
const { updateStatus } = useUpdateStatus()
const { deleteStatus } = useDeleteStatus()

const canMutate = computed(
  () => (props.source === 'board' && (visibleStatuses.value?.length ?? 0) > 1) || props.source === 'settings-pack'
)

const visibleStatuses = computed(() =>
  props.statuses?.filter((status) => status.type === props.statusType.value)
)
const addInputVisible = ref(false)

const onCreateStatus = async (
  formState: { name: string },
  form: { setErrors: CallableFunction }
) => {
  if (!formState.name.trim()) {
    return
  }

  const data = await createStatus({
    name: formState.name.trim().slice(0, 16),
    type: props.statusType.value,
    parentId: props.parentId,
    source: props.source,
  })
  if (data?.error) {
    return form.setErrors(parseGqlErrors(data.error.graphQLErrors))
  }

  emit('created')

  addInputVisible.value = false
  toast.add({
    title: 'Created status successfully',
  })
}

const onUpdateStatus = async (
  formState: { name: string },
  form: { setErrors: CallableFunction },
  status: Status
) => {
  if (!formState.name.trim()) {
    return
  }

  const data = await updateStatus({
    id: status.id,
    name: formState.name.trim().slice(0, 16),
    source: props.source,
    parentId: props.parentId,
  })
  if (data?.error) {
    return form.setErrors(parseGqlErrors(data.error.graphQLErrors))
  }

  toast.add({
    title: 'Updated status successfully',
  })
}

const onConfirmDeleteStatus = (status: Status) => {
  preventClose.value = true
  if (props.statuses?.length === 1) {
    modal.open(LazyDeleteModal, {
      heading: 'Delete status?',
      content:
        'If you delete this status, all configurations belong to this status will be deleted from all your boards, this action cannot be undone. Are you sure to continue?',
      confirmText: 'Delete status',
      onConfirm: async () => {
        const params: DeleteStatusParams = {
          id: status.id,
          replaceToId: null,
        }
        const { error } = await deleteStatus(params)

        if (error) {
          return toast.add({
            title: 'Something went wrong',
            color: 'red',
          })
        }

        modal.close()
        preventClose.value = true
      },
      onCancel: () => {
        modal.close()
        preventClose.value = false
      },
    })
    return
  }

  modal.open(LazyStatusSettingsDeleteModal, {
    status,
    onConfirm: () => {
      modal.close()
      preventClose.value = false
    },
    onCancel: () => {
      modal.close()
      preventClose.value = false
    },
  })
}
</script>
