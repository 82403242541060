<template>
  <template v-if="preview">
    <div ref="element" class="relative h-10 w-full" :class="statusClass.text">
      <span
        class="flex items-center h-full rounded-md outline-none text-sm font-medium w-full pl-11 pr-24"
        :class="['border', statusClass.bg, statusClass.border, { 'hover:!border-inherit': preview }]"
      >
        {{ formState.name }}
      </span>
      <div
        class="absolute flex items-center gap-2 left-0 top-1/2 px-2 -translate-y-1/2"
      >
        <StatusIcon :type="status.type" />
      </div>
    </div>
  </template>
  <template v-else>
    <div
      v-if="closestEdge === 'top'"
      class="w-full border-t border-primary-500"
    />
    <UForm
      ref="form"
      :schema="schema"
      :state="formState"
      @submit.prevent.left="$emit('save', formState, form)"
    >
      <UFormGroup label="" name="name">
        <div ref="element" class="relative h-10 w-full group/status" :class="statusClass.text">
          <UInput
            ref="input"
            v-model="formState.name"
            eager-validation
            class="h-full"
            maxlength="16"
            placeholder="Enter your status name"
            :ui="{
              base: 'flex items-center h-full rounded-md outline-none font-semibold w-full',
              padding: {
                sm: 'pl-11 pr-24',
              },
              placeholder: 'placeholder:font-normal',
              color: {
                white: {
                  outline: `${statusClass.bg} ${statusClass.border} border ring-0 focus:border-transparent`,
                },
              },
            }"
            @focus="isFocus = true"
            @blur="isFocus = false"
          />
          <div
            class="absolute flex items-center gap-2 left-0 top-1/2 px-2 -translate-y-1/2"
          >
            <div ref="dragElement" class="cursor-grab">
              <IconDrag />
            </div>
            <StatusIcon :type="status.type" />
          </div>
          <div
            v-if="isFocus"
            class="absolute z-10 right-0 top-1/2 px-2 -translate-y-1/2 flex items-center gap-2"
          >
            <UButton
              v-if="canMutate"
              color="white"
              variant="ghost"
              size="xs"
              icon="i-heroicons-trash"
              @mousedown.prevent
              @click="$emit('delete')"
            />
            <UButton v-if="formState.name?.trim() && formState.name !== status.name" size="xs" type="submit" @mousedown.prevent> Save </UButton>
          </div>
          <UDropdown
            v-if="!isFocus && status.id"
            :open="openQuickActions"
            :items="actionItems"
            class="absolute z-10 right-0 top-1/2 px-2 -translate-y-1/2 items-center gap-2 hidden group-hover/status:flex"
            @close="openQuickActions = false"
          >
            <UButton
              color="gray"
              variant="ghost"
              icon="i-heroicons:ellipsis-horizontal"
              size="xs"
              class="focus:ring-2 ring-primary-500"
              @click.prevent.stop="openQuickActions = !openQuickActions"
            />
            <div class="hidden" />
          </UDropdown>
        </div>
      </UFormGroup>
    </UForm>
    <div
      v-if="closestEdge === 'bottom'"
      class="w-full border-b border-primary-500"
    />
    <Teleport v-if="state.type === 'preview'" :to="state.container">
      <div
        class="box-border flex items-center border gap-2 relative h-10 rounded-md max-w-96 px-2 truncate text-sm font-semibold leading-10 -rotate-2"
        :class="Object.values(statusClass)"
        :style="{
          width: state.rect.width + 'px',
        }"
      >
        <IconDrag />
        <StatusIcon :type="status.type" />
        {{ formState.name }}
      </div>
    </Teleport>
  </template>
</template>

<script lang="ts" setup>
import { z } from 'zod'
import type { DropdownItem } from '#ui/types'
import type { Status } from '#status/types'
import type { STATUS_TYPE } from '#status/constant'
import { STATUS_TYPE_CLASS } from '#status/constant'

const props = defineProps({
  status: {
    type: Object as PropType<Partial<Status>>,
    default: () => ({}),
  },
  parentId: {
    type: String,
    required: true,
  },
  source: {
    type: String as PropType<MorphSource>,
    required: true,
  },
  preview: {
    type: Boolean,
    default: false,
  },
  canMutate: {
    type: Boolean,
    default: false,
  }
})

const emit = defineEmits(['save', 'delete'])

const schema = z.object({
  name: z.string().min(1).max(255),
})

const openQuickActions = ref(false)
const isFocus = ref(false)
const form = ref()
const input = ref()
const element = ref()
const dragElement = ref()
const formState = reactive({
  name: props.status.name,
})

const { state, closestEdge } = useStatusAsDropTarget({
  target: element,
  dragHandleTarget: dragElement,
  status: props.status as Status,
  parentId: props.parentId,
  source: props.source,
})

const statusClass = computed(() => {
  if (!props.status.id) {
    return {
      text: 'text-gray-700',
      bg: 'bg-gray-50',
      border: 'border-gray-200',
    }
  }

  return STATUS_TYPE_CLASS[props.status.type as STATUS_TYPE]
})

const actionItems = [
  [
    {
      label: 'Edit',
      icon: 'i-heroicons-pencil-square',
      class: 'text-gray-500',
      click: () => {
        input.value.$el.querySelector('input')?.focus()
      },
    },
  ],
  props.canMutate && [
    {
      label: 'Delete status',
      icon: 'i-heroicons-trash-solid',
      iconClass: 'text-red-500',
      class: 'text-red-500',
      click: () => {
        emit('delete')
      },
    },
  ],
].filter(Boolean) as DropdownItem[][]

onMounted(() => {
  if (!props.status.id && input.value.$el) {
    input.value.$el.querySelector('input')?.focus()
  }
})

watch(
  () => props.status.name,
  (name) => {
    formState.name = name
  })

watch(() => isFocus.value,
  () => {
    openQuickActions.value = false
  })
</script>
