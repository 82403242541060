import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine'
import {
  draggable,
  dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { getReorderDestinationIndex } from '@atlaskit/pragmatic-drag-and-drop-hitbox/util/get-reorder-destination-index'
import {
  attachClosestEdge,
  extractClosestEdge,
  type Edge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge'
import type { CleanupFn } from '@atlaskit/pragmatic-drag-and-drop/dist/types/internal-types'
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview'
import type { ElementState, TargetElement } from '#core/types'
import type { Status, StatusDraggableData } from '#status/types'

export const useStatusAsDropTarget = (params: {
  target: TargetElement
  dragHandleTarget?: TargetElement
  status: Status
  parentId: string
  source: MorphSource
}) => {
  const { moveStatus } = useStatusMove()
  const cleanup = ref<CleanupFn>()
  const state = ref<ElementState>({
    type: 'idle',
  })
  const closestEdge = ref<Edge | null>(null)

  onMounted(() => {
    const {
      target,
      dragHandleTarget,
      status,
      parentId,
      source: statusSource,
    } = params
    const element = unrefElement(evaluativeFn(target))
    const dragHandle = unrefElement(evaluativeFn(dragHandleTarget))
    if (status.id) {
      cleanup.value = combine(
        draggable({
          element,
          dragHandle,
          getInitialData: () => ({
            type: 'status',
            id: status.id,
            position: status.position,
            statusType: status.type,
          }),
          onGenerateDragPreview: ({ location, source, nativeSetDragImage }) => {
            const rect = source.element.getBoundingClientRect()
            setCustomNativeDragPreview({
              nativeSetDragImage,
              getOffset: () => {
                const offsetX = Math.min(
                  location.current.input.clientX - rect.x,
                  rect.width
                )
                const offsetY = Math.min(
                  location.current.input.clientY - rect.y,
                  rect.height
                )
                return { x: offsetX, y: offsetY - rect.height / 2 + 16 }
              },
              render({ container }) {
                state.value = {
                  type: 'preview',
                  container,
                  rect,
                }
                return () => {
                  state.value = {
                    type: 'dragging',
                  }
                }
              },
            })
          },
          onDragStart: () => {
            state.value = {
              type: 'dragging',
            }
          },
          onDrop: () => {
            state.value = {
              type: 'idle',
            }
          },
        }),
        dropTargetForElements({
          element,
          canDrop: (args) => args.source.data.type === 'status',
          getIsSticky: () => true,
          getData: ({ input, element }) => {
            const data = {
              type: 'status',
              id: status.id,
              position: status.position,
              statusType: status.type,
            }
            return attachClosestEdge(data, {
              input,
              element,
              allowedEdges: ['top', 'bottom'],
            })
          },
          onDragEnter: (args) => {
            const item = args.source.data
            if (item?.id !== status.id) {
              closestEdge.value = extractClosestEdge(args.self.data)
            }
          },
          onDrag: (args) => {
            const item = args.source.data
            if (item?.id !== status.id) {
              closestEdge.value = extractClosestEdge(args.self.data)
            }
          },
          onDragLeave: () => {
            closestEdge.value = null
          },
          onDrop: ({ self, source }) => {
            closestEdge.value = null
            const item = source.data as StatusDraggableData
            const destinationItem = self.data as StatusDraggableData
            const currentClosestEdge = extractClosestEdge(destinationItem)
            const statuses = useReadStatusesFromCache(parentId, statusSource)
            const statusTypes = statuses.filter((_) => _.type === status.type)
            let indexOfTarget = statusTypes.findIndex((_) => _.id === status.id)
            if (indexOfTarget === -1) {
              indexOfTarget = statusTypes.length
            }

            if (status.type !== item.statusType) {
              const destinationIndex =
                currentClosestEdge === 'bottom'
                  ? indexOfTarget + 1
                  : indexOfTarget
              return moveStatus({
                id: item.id,
                index: destinationIndex,
                type: status.type,
                parentId,
                source: statusSource,
              })
            }

            const startIndex = statusTypes.findIndex((_) => _.id === item.id)
            const destinationIndex = getReorderDestinationIndex({
              startIndex,
              indexOfTarget,
              closestEdgeOfTarget: currentClosestEdge,
              axis: 'vertical',
            })
            moveStatus({
              id: item.id,
              index: destinationIndex,
              type: status.type,
              parentId,
              source: statusSource,
            })
          },
        })
      )
    }
  })

  onBeforeUnmount(() => {
    cleanup.value?.()
  })

  return {
    state,
    closestEdge,
  }
}
