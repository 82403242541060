import { cloneDeep } from 'lodash-es'
import type { STATUS_TYPE } from '#status/constant'
import type {
  CreateStatusParams,
  MoveStatusParams,
  Status,
  UpdateStatusParams,
  DeleteStatusParams,
} from '#status/types'
import { STATUSES_QUERY } from '#status/schema'
import { INDEX_STEP } from '#core/constant'

export const useReadStatusesFromCache = (
  parentId: string,
  source: MorphSource
) => {
  const { client } = useApolloClient()
  const cache = client.readQuery<{ statuses: Status[] }>({
    query: STATUSES_QUERY,
    variables: {
      parentId,
      source,
    },
  })
  const statuses = cloneDeep(cache?.statuses)
  return statuses ? statuses?.sort(sortPos) : []
}

export const useCreateStatus = () => {
  const { getOptimisticStatus } = useStatusDataConvert()
  const createStatus = async (params: Omit<CreateStatusParams, 'position'>) => {
    const settingsPackId = params.source === 'settings-pack' ? params.parentId : null
    const statuses = useReadStatusesFromCache(params.parentId, params.source)
    const statusTypes = statuses.filter((status) => status.type === params.type)
    const position = statusTypes.length
      ? (statusTypes.pop()?.position || 0) + 1
      : INDEX_STEP
    const optimistic = getOptimisticStatus(null, {
      ...params,
      position,
      settingsPackId,
    })

    const { mutate, error, loading } = useAddUpdateStatusMutation({
      variables: {
        input: {
          ...params,
          position,
        },
      },
      optimisticResponse: {
        addUpdateStatus: {
          status: optimistic,
        },
        optimistic: true,
      },
      update: (_, { data }) => {
        if (data?.addUpdateStatus?.status && params.source === 'board') {
          const { listCache } = useStatusSync()
          listCache.add(params.parentId, data.addUpdateStatus.status)
        }
      },
    })

    if (loading.value) {
      return
    }

    const data = await mutate()
    if (error.value || !data?.data?.addUpdateStatus) {
      return {
        error: error.value,
      }
    }

    return {
      data: data?.data?.addUpdateStatus,
    }
  }

  return {
    createStatus,
  }
}

export const useUpdateStatus = () => {
  const { client } = useApolloClient()
  const { getStatusIdentifier, getOptimisticStatusFields } = useStatusDataConvert()

  const updateStatus = async (params: Partial<UpdateStatusParams>) => {
    client.cache.modify({
      id: getStatusIdentifier(params.id as string),
      fields: getOptimisticStatusFields(params),
      optimistic: true,
    })

    const { mutate, error } = useAddUpdateStatusMutation({
      variables: {
        input: params,
      },
    })

    await mutate()
    if (error.value) {
      return {
        error: error.value,
      }
    }
  }

  return {
    updateStatus,
  }
}

export const useStatusMove = () => {
  const { client } = useApolloClient()
  const { getStatusIdentifier } = useStatusDataConvert()

  const moveStatus = async (params: MoveStatusParams) => {
    const { id, index, type, parentId, source } = params
    const statuses = useReadStatusesFromCache(parentId, source)
    const statusTypes = statuses.filter((_) => _.type === type)
    const statusIndex = statusTypes.findIndex((status) => status.id === id)
    const position = calculateItemPosition(
      index,
      statusTypes,
      statusTypes[statusIndex]
    )
    const updateCache = (position: number, type: STATUS_TYPE) => {
      client.cache.modify({
        id: getStatusIdentifier(id),
        fields: {
          position: () => position,
          type: () => type,
        },
        optimistic: true,
      })
    }

    updateCache(position, type)
    const { mutate, error } = useUpdateStatusPositionMutation({
      id,
      position,
      type,
    })
    if (error.value) {
      return updateCache(
        statusTypes[statusIndex].position,
        statusTypes[statusIndex].type
      )
    }

    await mutate()
  }

  return {
    moveStatus,
  }
}

export const useDeleteStatus = () => {
  const { client } = useApolloClient()
  const { getStatusIdentifier } = useStatusDataConvert()

  const deleteStatus = async (params: DeleteStatusParams) => {
    const { mutate, error } = useDeleteStatusMutation(params)

    const data = await mutate()

    if (error.value || !data?.data?.deleteStatus) {
      return {
        error: error.value,
      }
    }

    client.cache.evict({
      id: getStatusIdentifier(params.id),
    })

    return { data: data?.data?.deleteStatus }
  }

  return {
    deleteStatus,
  }
}
