import type * as Apollo from '@apollo/client'
import {
  ADD_UPDATE_STATUS_MUTATION,
  DELETE_STATUS_MUTATION,
  STATUSES_QUERY,
} from '#status/schema'
import type {
  DeleteStatusParams,
  UpdateStatusPosParams,
  Status,
} from '#status/types'

export const useStatusesQuery = (params: {
  parentId: string
  source: MorphSource
}) => {
  return useQuery<{ statuses: Status[] }>(STATUSES_QUERY, params)
}

export const useStatusesLazyQuery = (params: {
  parentId: string
  source: MorphSource
}) => {
  return useLazyQuery<{ statuses: Status[] }>(STATUSES_QUERY, params)
}

export const useAddUpdateStatusMutation = (
  options: Apollo.MutationHookOptions<{
    addUpdateStatus: { status: Status }
  }>
) => {
  return useMutation(ADD_UPDATE_STATUS_MUTATION, {
    throws: 'never',
    ...options,
  })
}

export const useUpdateStatusPositionMutation = (params: UpdateStatusPosParams) => {
  return useMutation(ADD_UPDATE_STATUS_MUTATION, {
    variables: { input: params },
    throws: 'never',
  })
}

export const useDeleteStatusMutation = (params: DeleteStatusParams) => {
  return useMutation(DELETE_STATUS_MUTATION, {
    variables: params,
    throws: 'never',
  })
}
